$( document ).ready(function() {
    $("#bootgrid_table").bootgrid({
            caseSensitive: false,
            labels: {
                all: 'Todo',
                infos: '<strong>{{ctx.start}}-{{ctx.end}}</strong> de <strong>{{ctx.total}}</strong>',
                loading: 'Cargando...',
                noResults: 'No se encontraron resultados.',
                refresh: 'Actualizar',
                search: 'Buscar',
            },
            formatters: {
                commands: function (column, row)
                {
                    /* "this" is mapped to the grid instance */
                    return "html code";
                },
                "link": function(column, row)
                {
                    return "<a href=\"" + window.location.href + '/' + row.id +"\">" + row.id + "</a>";
                },
                "user-image": function(column, row)
                {
                    return '<img class="img-circle img-circle-zoom" id="user-image" style="max-width: 30px;" src="'+row["image"]+'" />';
                },
                "familia-id": function(column, row)
                {
                    if(row["familia-id"])
                        return "<a class=\"btn btn-default btn-xs\" href=\"/estudiante/familia/" + row["familia-id"] +"\">" + row["familia-id"] + '</a>';
                },
                "user-id": function(column, row)
                {
                    if(row["user-id"])
                        return "<a class=\"btn btn-default btn-xs\" href=\"/config/user/" + row["user-id"] +"\">" + row["user-id"] + '</a>';
                },
                "progressbar": function(column, row)
                {
                    function getColor(value){
                        value = value / 100;
                        var hue=((value)*200).toString(10);
                        return ["hsl(",hue,",70%,50%)"].join("");
                    }
                    progressbar_value = row["progressbar-value"];
                    return '<div class="progress" style="position: relative;border: 1px solid #dedede;border-radius: 4px;background-color: #c1c1c1;margin-top: 0;">\
                                <div class="progress-bar" style="width:'+progressbar_value+'%;background:'+getColor(progressbar_value)+';" >\
                                <span style="position: absolute;display: block;width: 100%;color: white;">'+progressbar_value+'%</span>\
                              </div>\
                            </div>';
                },
                "truefalse": function(column, row)
                {
                    if(row["truefalse"] == 1)
                        return '<i class="fa fa-check-circle" aria-hidden="true" style="color:#00A65A;font-size: 1.5em !important;"></i>';
                    return '<i class="fa fa-times-circle" aria-hidden="true" style="color:#D9534F;font-size: 1.5em !important;"></i>';
                }
            },
            converters: {
                //recibe array y lo muestra cada elemento en una linea dentro de la misma celda
                listDisplayOrdered: {
                    from: function(value) {
                        return value;
                    },
                    to: function(value) {
                        // Here you can customise value - I have an Array which I join using <br>
                        value = JSON.parse(value); //convierte json recibido a js array.
                        value.sort();
                        value = value.join("<br/>");
                        return value;
                    }
                }
            },

            selection: true,
            multiSelect: true,
            rowSelect: false,
        })
        .on("selected.rs.jquery.bootgrid", function(e, rows)
            {
                renderButtons('selected', rows);
            })
        .on("deselected.rs.jquery.bootgrid", function(e, rows)
            {
                renderButtons('deselected', rows);
            });




    var htmlcode = '\
    <div class="row pull-left">\
    <div class="col-md-12 panel-title">\
    <div class="form-group" id="actionButtons">\
    </div></div></div>';
    $(".actionBar").append(htmlcode)

    if (typeof buttons !== 'undefined'){ //si existe buttons (no sacar porque da error js y tranca ejecucion)
        if (buttons["showAdd"]){
            showAddBtn();
        }
    }
    showCustomButtons("none", '');

    function renderButtons(action, rows){
        if(cantidadFilasSeleccionadas() == 0)
        {
            hideEditBtn();
            hideDeleteBtn();
            hideCustomButtons("single");
            hideCustomButtons("multi");
            showCustomButtons("none");
        }
        if(cantidadFilasSeleccionadas() == 1)
        {
            hideEditBtn();
            hideDeleteBtn();
            hideCustomButtons("none");
            hideCustomButtons("single");
            hideCustomButtons("multi");
            showEditBtn();
            showDeleteBtn();
            showCustomButtons("single", rows);
            showCustomButtons("multi", rows);
            showCustomButtons("none", rows);
            hideCustomButtons("none");
        }
        if(cantidadFilasSeleccionadas() > 1)
        {
            hideEditBtn();
            hideDeleteBtn();
            hideCustomButtons("single");
            hideCustomButtons("none");
            
            if(action == 'selected')
            {
                //hideCustomButtons("none");
                hideCustomButtons("multi");
                showCustomButtons("multi", rows);
                //showCustomButtons("none", rows);
            }
            if(action == 'deselected')
            {
                showCustomButtons("multi", rows);
                hideCustomButtons("multi");
                //showCustomButtons("none", rows);
                //hideCustomButtons("none");
            }
        }
        if(cantidadFilasSeleccionadas() > 2)
        {   
            showCustomButtons("multi", rows);
            hideCustomButtons("multi");
            showCustomButtons("none", rows);
            hideCustomButtons("none");
        }
    }


    function showCustomButtons(selectionType, rows){
        if (existCustomButtons()){ //si existe customButtons
            for (var button=0; button<customButtons.length; button++){
                if(customButtons[button]["btnSelection"] == selectionType)
                {
                    var btnHref = customButtons[button]["btnHref"];
                    var btnId = customButtons[button]["btnId"];
                    var btnClass = customButtons[button]["btnClass"];
                    var btnText = customButtons[button]["btnText"];
                    var btnTooltip = customButtons[button]["btnTooltip"];
                    var btnHtmlAtts = customButtons[button]["btnHtmlAtts"];
                    var btnFunction = customButtons[button]["btnFunction"];
                    var btnAfterFunction = customButtons[button]["btnAfterFunction"];

                    rowId = selectedRows();

                    if (selectionType=="none")
                    {
                        var html = '<a href="'+btnHref+'" type="button" id="'+btnId+'" class="btn btn-'+btnClass+'" '
                        if (typeof(btnTooltip) != "undefined" && btnTooltip != "") {
                            html = html + 'data-toggle="tooltip" title="'+btnTooltip+'" data-placement="bottom"';
                        }
                        if (typeof(btnHtmlAtts) != "undefined") {
                            html = html + ' ' + btnHtmlAtts + ' ';
                        }
                        var html = html + 'style="margin: 0px 4px 0px 0px;">'+btnText+'</a> ';
                    }
                    if (selectionType=="single")
                    {   
                        if(btnFunction!="")
                        {
                            if(result = window[btnFunction](rowId)>0)
                            {
                                var html = '<a href="'+btnHref+'/'+selectedRow()+'" type="button" id="'+btnId+'" class="btn btn-'+btnClass+'" '
                                if (typeof(btnTooltip) != "undefined" && btnTooltip != "") {
                                    html = html + 'data-toggle="tooltip" title="'+btnTooltip+'" data-placement="bottom"';
                                }
                                if (typeof(btnHtmlAtts) != "undefined") {
                                    html = html + ' ' + btnHtmlAtts + ' ';
                                }
                                html = html + 'style="margin: 0px 4px 0px 0px;">'+btnText+'</a> ';
                            }
                            else
                            {
                                //si no cumple condicion de la funcion, html vacio para no agregar el boton
                                html = '';
                            }
                        }
                        else
                        {
                            var html = '<a href="'+btnHref+'/'+selectedRow()+'" type="button" id="'+btnId+'" class="btn btn-'+btnClass+'" '
                            if (typeof(btnTooltip) != "undefined" && btnTooltip != "") {
                                html = html + 'data-toggle="tooltip" title="'+btnTooltip+'" data-placement="bottom"';
                            }
                            if (typeof(btnHtmlAtts) != "undefined") {
                                html = html + ' ' + btnHtmlAtts + ' ';
                            }
                            html = html + 'style="margin: 0px 4px 0px 0px;">'+btnText+'</a> ';
                        }
                    }
                    if (selectionType=="multi")
                    {
                        var html = '<a href="'+btnHref+'/'+selectedRows()+'" type="button" id="'+btnId+'" class="btn btn-'+btnClass+'" '
                        if (typeof(btnTooltip) != "undefined" && btnTooltip != "") {
                            html = html + 'data-toggle="tooltip" title="'+btnTooltip+'" data-placement="bottom"';
                        }
                        if (typeof(btnHtmlAtts) != "undefined") {
                            html = html + ' ' + btnHtmlAtts + ' ';
                        }
                        html = html + 'style="margin: 0px 4px 0px 0px;">'+btnText+'</a> ';
                    }

                    // $("#actionButtons").append(html);
                    //el delay es para que no se solape con el fadeOut de los otros botones
                    if (document.getElementById(btnId) != null)
                    {
                        //ya existe el boton, lo hago sin animación
                        $(html).appendTo("#actionButtons").delay(150);
                    }
                    else
                    {
                        $(html).appendTo("#actionButtons").delay(150).hide().fadeIn(150, function(){ $(this).show();});
                    }
                    if(btnAfterFunction)
                        window[btnAfterFunction]();
                }
            }//end for
        }
    }

    function hideCustomButtons(selectionType){
        if (existCustomButtons()){
            for (var button=0; button<customButtons.length; button++){
                if(customButtons[button]["btnSelection"] == selectionType)
                {
                    var btnId = customButtons[button]["btnId"];
                    // $("#"+btnId).fadeOut(150, function(){ $(this).remove();});;
                    if (document.getElementById(btnId) != null)
                    {
                        //ya existe el boton, lo hago sin animación
                        $("#"+btnId).remove();
                    }
                    else
                    {
                        $("#"+btnId).fadeOut(150, function(){ $(this).remove();});;
                    }
                }
            }
        }
    }



    function showAddBtn(){
        var html = '<a href="'+window.location.href +'/create" type="button" id="actionButtonsAddBtn" class="btn btn-success">Agregar</a> ';
        $("#actionButtons").append(html);
    }

    function showEditBtn(){
        if (buttons["showEdit"]){
            var html = '<a href="'+window.location.href +'/'+selectedRow()+'/edit" type="button" id="actionButtonsEditBtn" class="btn btn-primary" style="display: inline-block;';
            (buttons["showDelete"]) ? html+='border-radius: 3px 0px 0px 3px !important;' : html+='margin: 0 4px;margin-left: 0px;';
            html += '"><i class="fa fa-pencil-square-o"></i></a>';
            $(html).appendTo("#actionButtons").hide().fadeIn(150, function(){ $(this).show();});
        }
    }

    function showDeleteBtn(){
        if (buttons["showDelete"]){
            var html = '<a href="'+window.location.href +'/'+selectedRow()+'" data-method="delete" data-token="' + csrf_token() + '" data-confirm="Seguro?" type="button" id="actionButtonsDeleteBtn" class="btn btn-danger" style="display: inline-block;margin: 0 4px;';
            (buttons["showEdit"]) ? html+='border-radius: 0px 3px 3px 0px !important;margin-left: 0px;' : html+='margin: 0 4px;margin-left: 0px;';
            html +='"><i class="fa fa-trash-o"></i></a>';
            $(html).appendTo("#actionButtons").hide().fadeIn(150, function(){ $(this).show();});
            deleteMethod(window, $, undefined);
        }
    }

    function hideEditBtn(){
        if (buttons["showEdit"]){
            $("#actionButtonsEditBtn").fadeOut(150, function(){ $(this).remove();});
        }
    }

    function hideDeleteBtn(){
        if (buttons["showDelete"]){
            $("#actionButtonsDeleteBtn").fadeOut(150, function(){ $(this).remove();});;
        }
    }








    function selectedRow(){
        if ( $("tr.active").is("[aria-selected]") ){
            var rowId = $("tr.active").attr("data-row-id");
        }
        return rowId;
    }
    function selectedRows(){
        if ( $("tr.active").is("[aria-selected]") ){
            selectedRowsIds = [];
            $.each($("tr.active"),function(i,v){
                selectedRowsIds.push($(this).attr("data-row-id"));
            });
            return selectedRowsIds;
        }
        return 0;
    }
    function existCustomButtons(){
        if (typeof(customButtons) != "undefined")
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    function cantidadFilasSeleccionadas(){
        return $("td.select-cell").children().children(':checked').length;
    }






    function csrf_token(){
        var token = $('meta[name="csrf-token"]').attr('content');
        return token;
    }

    

}); //document ready